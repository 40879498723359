import {RESET_REGISTRATION, SAVE_REGISTRATION} from "./registrationActionTypes"

/**
 * Reducer "Registration"
 * Conserve les informations d'inscription dans le store
 */
const registration = (state = {registration: {}}, action = {}) => {
    switch (action.type) {
        case SAVE_REGISTRATION:
            return action.registration
        case RESET_REGISTRATION:
            return {}

        default:
            return state
    }
}

export default registration

/**
 * Retourne les informations d'instruction
 */
export const getRegistration = state => state
