import {isEmpty} from "lodash"
import {getFilenameFromContentDisposition} from "./../../shared-components/utils/headerUtil"
import {PERSONNALISATION_ENDPOINT} from "../../constants/endpoints"
import {EBSRequestKeys} from "../../constants/requests"
import {genericThunk} from "../../shared-components/actions/actionsUtils"
import HttpMethod from "../../shared-components/common/constants/httpMethods"

/**
 * On récupère les informations de personnalisation par numéro de client
 */
export const getPersonnalisationByNumClient = numClient => dispatch => {
    const payloadHandler = response => {
        return response.data
    }

    return genericThunk(
        undefined,
        HttpMethod.GET,
        dispatch,
        PERSONNALISATION_ENDPOINT.getPersonnalisationByNumClient(numClient),
        undefined,
        EBSRequestKeys.PERSONNALISATION,
        payloadHandler,
    )
}

export const getPersonnalisationLogoByNumClient = numClient => dispatch => {
    const payloadHandler = response => {
        if (!isEmpty(response.data)) {
            let reader = new FileReader()
            reader.readAsDataURL(response.data)
        }
        return {
            file: response.data,
            fileName: getFilenameFromContentDisposition(response.headers["content-disposition"]),
            numClient: numClient,
        }
    }

    return genericThunk(
        undefined,
        HttpMethod.GET,
        dispatch,
        PERSONNALISATION_ENDPOINT.getPersonnalisationLogoByNumClient(numClient),
        undefined,
        EBSRequestKeys.PERSONNALISATION_LOGO,
        payloadHandler,
        "blob",
    )
}

export const getPersonnalisationClientsLogos = () => dispatch => {
    const payloadHandler = response => {
        return response.data
    }

    return genericThunk(
        undefined,
        HttpMethod.GET,
        dispatch,
        PERSONNALISATION_ENDPOINT.getPersonnalisationClientsLogos(),
        undefined,
        EBSRequestKeys.PERSONNALISATION_CLIENTS_LOGOS,
        payloadHandler,
    )
}
