import {CLEAR_LOCAL_STORAGE_VALUE, SAVE_LOCAL_STORAGE_VALUE} from "./localStorageActionTypes"

export const saveLocalStorageValue = (key, value) => ({
    type: SAVE_LOCAL_STORAGE_VALUE,
    key,
    value,
})

export const clearLocalStorageValue = key => ({
    type: CLEAR_LOCAL_STORAGE_VALUE,
    key,
})
