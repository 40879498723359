/***************************************/
/*              SERVICES               */
/***************************************/

const SERVICE_API = "/api/service"
const SERVICE_ESPACE_BENEFICIAIRE = "/api/espace-beneficiaire-service/service"
const AUTH = "/auth"

/**
 * Endpoints for AuthAPI
 */
export const AUTH_ENDPOINTS = {
    logout: `${SERVICE_API}${AUTH}/logout`,
    recoverLoginPassword: SERVICE_API + AUTH + "/recover",
}

export const PARAMETER_ENDPOINT = {
    getParameterFromId: paramId => "/api/service/parametres/" + paramId,
}

export const BENEFICIAIRE_ENDPOINTS = {
    getBeneficiaireRelatives: beneficiaireId =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/profil-proches`,
    deleteOrUpdateBeneficiaryRelative: (beneficiaireId, relativeId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/profil-proches/${relativeId}`,
    getBeneficiaireProfileMainInformations: beneficiaireId =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/profil-informations-principales`,
    getBeneficiaireProfileInformationsConnexion: beneficiaireId =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/profil-informations-connexion`,
    getBeneficiaryComplementaryInfos: beneficiaireId =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/profil-informations-complementaires`,
}

export const ZONE_INTERVENTION_ENDPOINT = {
    getLastEnableZoneIntervention: (beneficiaryId, contractId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/zone-intervention/derniere-zone-intervention`,
    retrieveEligibleZonesIntervention: (beneficiaryId, contractId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/zone-intervention/zones-intervention-eligibles`,
}

export const PROCHE_ENDPOINTS = {
    getProcheProfileMainInformations: `${SERVICE_ESPACE_BENEFICIAIRE}/proche/profil-informations-principales`,
    updateProfileInformationsConnexion: `${SERVICE_ESPACE_BENEFICIAIRE}/proche/profil-informations-connexion`,
}

export const COMMANDE_ENDPOINTS = {
    calculateTotalAmount: (beneficiaireId, clientId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${clientId}/commande/${contractNumber}/calculer-montants`,
    checkAttribution: (beneficiaireId, clientId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${clientId}/commande/${contractNumber}/verification-attribution`,
    checkDemandeUrgente: (contractNumber, beneficiaireId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/commande/${contractNumber}/verification-demande-urgente`,
    checkAgircAttribution: (beneficiaireId, clientId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${clientId}/commande/${contractNumber}/verification-attribution-agirc`,
    createPrestation: (beneficiaireId, clientId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${clientId}/commande/${contractNumber}/creer-prestation`,
    export: `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/export`,
    getCommandes: beneficiaireId => `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/commandes`,
    getCommandeDetails: (beneficiaireId, numCommande) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/commandes/${numCommande}`,
    getAgircCommandeDetails: (beneficiaireId, numCommande) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/commandes/agirc/${numCommande}`,
    getCompteurBeneficiaire: (beneficiaireId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/contrat/${contractNumber}/compteur`,
    getQuestionsAttribution: (beneficiaireId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/contrat/${contractNumber}/questions-attribution`,
    cancelCommande: (beneficiaireId, numCommande) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/commandes/${numCommande}/annuler`,
    getCommandesNumber: `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/numeros-commandes`,
    getContratInfoCompFilledWithBeneficiaireInfos: (beneficiaireId, clientId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${clientId}/commande/${contractNumber}/informations-complementaires`,
    getContratInfosAdditionnelles: (beneficiaireId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/contrat/${contractNumber}/informations-additionnelles`,
    getDispositifs: `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/dispositifs`,
    getDispositifsForCommandeBeneficiaireAndClientId: (beneficiaireId, clientId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${clientId}/commande/dispositif`,
    getContratsWithActiveCounterForBeneficiaireAndClientId: (beneficiaireId, clientId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${clientId}/dispositif/compteur`,
    getFacturesForCommandeAndBenefId: (numCommande, beneficiaireId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/commande/${numCommande}/factures`,
    getBillAttachmentByBillId: (beneficiaireId, factureId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/facture/${factureId}`,
    getMandatBeneficiaireActif: beneficiaireId =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/mandat`,
    getChequeActif: (beneficiaireId, contractNumber) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/${contractNumber}/cheque`,
    getCgu: idBeneficiaire => `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${idBeneficiaire}/cgu`,
    checkBeneficiaireCanCreateCommande: (beneficiaireId, idClient) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${idClient}/verifie-peut-commander`,
}

export const DEMANDE_CONTACT_ENDPOINT = {
    createDemandeInformation: (beneficiaireId, numClient) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${numClient}/demande-information`,
    createDemandeRappel: (beneficiaireId, numClient) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/${beneficiaireId}/client/${numClient}/demande-rappel`,
}

export const COMMANDE_PRESTATION_FIELDS_ENDPOINTS = {
    getPrestationFields: idTypePrestation =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/type-prestation/${idTypePrestation}/champs`,
}

export const CONTRAT_ENDPOINTS = {
    getContratReglesInstruction: contractId => `${SERVICE_ESPACE_BENEFICIAIRE}/regle-instruction/${contractId}`,
    displayFrequencyAttributionRules: (contractId, beneficiaryId) =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/contrat/${contractId}/affichage-questions-attributions/beneficiaire/${beneficiaryId}`,
}

export const GET_INFOS_UTILISATEUR = `${SERVICE_ESPACE_BENEFICIAIRE}/utilisateur`

export const PERSONNALISATION_ENDPOINT = {
    getPersonnalisationByNumClient: clientNumber => `${SERVICE_ESPACE_BENEFICIAIRE}/personnalisation/${clientNumber}`,
    getPersonnalisationLogoByNumClient: clientNumber =>
        `${SERVICE_ESPACE_BENEFICIAIRE}/personnalisation/${clientNumber}/logo`,
    getPersonnalisationClientsLogos: () => `${SERVICE_ESPACE_BENEFICIAIRE}/personnalisation/logos-clients`,
}

export const REFS_ENDPOINT = {
    refLibellesRefTypeInfoCompl: `${SERVICE_ESPACE_BENEFICIAIRE}/refs/ref-libelles-ref-type-info-comp`,
    regGroupeIrcAgirc: `${SERVICE_ESPACE_BENEFICIAIRE}/refs/ref-groupe-irc-agirc`,
    refTypeInfoCompl: `${SERVICE_ESPACE_BENEFICIAIRE}/refs/ref-type-info-complementaire`,
}

export const REGISTRATION_ENDPOINTS = {
    checkBeneficiaire: `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/verification-inscription`,
    checkBeneficiaireToken: `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/verification-token`,
    checkProche: `${SERVICE_ESPACE_BENEFICIAIRE}/proche/verification-inscription`,
    checkProcheToken: `${SERVICE_ESPACE_BENEFICIAIRE}/proche/verification-token`,
    createBeneficiaireUserAccount: `${SERVICE_ESPACE_BENEFICIAIRE}/beneficiaire/inscription`,
    createProcheUserAccount: `${SERVICE_ESPACE_BENEFICIAIRE}/proche/inscription`,
}

export const DISTANCE_ENDPOINTS = {
    getDistanceBetweenAdresses: `${SERVICE_ESPACE_BENEFICIAIRE}/calcul-distance/reel`,
}

/**
 * Endpoint to fetch information about the connected user
 */
export const GET_USER_INFO_ENDPOINT = "/api/service/auth/connected"

/**
 * Endpoint to fetch profile information about the connected user
 */
export const GET_USER_PROFILE_INFO_ENDPOINT = "/api/service/compte-utilisateur/profil/informations"

export const FAQ_ENDPOINT = `${SERVICE_ESPACE_BENEFICIAIRE}/faq`

/**
 * Endpoints Scellius
 */
export const SCELLIUS_ENDPOINTS = {
    getScelliusData: idBeneficiaire => SERVICE_API + `/paiement/${idBeneficiaire}/initialiser-formulaire-mandat`,
}
