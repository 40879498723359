/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
import React from "react"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import history from "../history"
import {SERVICE_BENEFICIARY_AREA, SERVIDOM_AREA} from "../shared-components/constants/areaTypes"
import {
    getChangePasswordErrorMessage,
    isFetchingChangePassword,
} from "../store/selectors/temporaries/changePasswordSelectors"
import {
    getResetPasswordDisplaySuccessMessage,
    getResetPasswordScore,
} from "../store/selectors/temporaries/resetPasswordSelectors"

// ASYNC LAYOUTS
export const AsyncRoutingLayout = React.lazy(() => import("./routingLayout").catch(() => window.location.reload()))

// ASYNC SCENES
const HomeScene = React.lazy(() => import("../scenes/Home/homeContainer").catch(() => window.location.reload()))

export const AsyncHomeScene = () => <HomeScene />

export const AsyncContactScene = React.lazy(() =>
    import("../scenes/Contact/contact").catch(() => window.location.reload()),
)

export const AsyncFAQScene = React.lazy(() => import("../scenes/FAQ/faq").catch(() => window.location.reload()))

export const AsyncRgpdScene = React.lazy(() => import("../scenes/RGPD/rgpd").catch(() => window.location.reload()))

export const AsyncAreaChoiceScene = React.lazy(() =>
    import("../scenes/AreaChoice/areaChoice").catch(() => window.location.reload()),
)

export const AsyncClientChoiceScene = React.lazy(() =>
    import("../scenes/ClientChoice/clientChoice").catch(() => window.location.reload()),
)

export const AsyncCommandeChoixDispositifScene = React.lazy(() =>
    import("../scenes/Commande/commandeDispositifChoiceScene").catch(() => window.location.reload()),
)

export const AsyncCommandeInfosComplementairesScene = React.lazy(() =>
    import("../scenes/Commande/commandeInfosComplementairesScene").catch(() => window.location.reload()),
)

export const AsyncCommandeAttributionScene = React.lazy(() =>
    import("../scenes/Commande/commandeAttributionScene").catch(() => window.location.reload()),
)

export const AsyncCommandePrestationScene = React.lazy(() =>
    import("../scenes/Commande/commandePrestationScene").catch(() => window.location.reload()),
)

export const AsyncCommandeInformationsPaiementScene = React.lazy(() =>
    import("../scenes/Commande/commandeInformationsPaiementScene").catch(() => window.location.reload()),
)

export const AsyncCommandeConfirmationScene = React.lazy(() =>
    import("../scenes/Commande/commandeConfirmationScene").catch(() => window.location.reload()),
)

const UnauthorizedScene = React.lazy(() =>
    import("../shared-components/scenes/Unauthorized/unauthorizedContainer").catch(() => window.location.reload()),
)

export const AsyncUnauthorizedScene = () => (
    <UnauthorizedScene
        onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)}
        unauthorizedPage={Page.UNAUTHORIZED}
    />
)

const NotFoundScene = React.lazy(() => import("../scenes/NotFound/notFound").catch(() => window.location.reload()))

export const AsyncNotFoundScene = () => (
    <NotFoundScene notFoundPage={Page.NOT_FOUND} onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)} />
)

const TechnicalErrorScene = React.lazy(() =>
    import("../shared-components/components/ErrorBoundary/errorBoundary").catch(() => window.location.reload()),
)

export const AsyncTechnicalErrorScene = () => (
    <TechnicalErrorScene
        page={Page.TECHNICAL_ERROR}
        redirectToHomePage={() => history.push(PagesArchitecture[Page.HOME].route)}
    />
)

const LegalNoticeScene = React.lazy(() =>
    import("../shared-components/scenes/LegalNotice/legalNoticeContainer").catch(() => window.location.reload()),
)

export const AsyncLegalNoticeScene = () => (
    <LegalNoticeScene
        areaType={SERVIDOM_AREA}
        legalNoticePage={Page.LEGAL_NOTICE}
        redirectToLoginPage={() => history.push(PagesArchitecture[Page.HOME].route)}
        withCGS={false}
    />
)

export const AsyncRegistrationProfileScene = React.lazy(() =>
    import("../scenes/RegistrationProfile/registrationProfile").catch(() => window.location.reload()),
)

export const AsyncForgottenLoginOrPasswordScene = React.lazy(() =>
    import("../scenes/ForgottenLoginOrPassword/forgottenLoginOrPassword").catch(() => window.location.reload()),
)

export const AsyncProfileScene = React.lazy(() =>
    import("../scenes/Profile/profile").catch(() => window.location.reload()),
)

export const AsyncProfileRelativeAddOrEditScene = React.lazy(() =>
    import("../scenes/Profile/ProfileRelatives/profileRelativesAddOrEdit").catch(() => window.location.reload()),
)

export const AsyncCommandeScene = React.lazy(() =>
    import("../scenes/Commande/commandeScene").catch(() => window.location.reload()),
)

export const AsyncVisualisationModificationScene = React.lazy(() =>
    import("../scenes/Commande/commandeVisualisationModificationScene").catch(() => window.location.reload()),
)

const StyleGuideScene = React.lazy(() =>
    import("../shared-components/scenes/StyleGuide/styleGuide").catch(() => window.location.reload()),
)

export const AsyncStyleGuideScene = () => <StyleGuideScene />

const ResetPasswordScene = React.lazy(() =>
    import("../scenes/ResetPassword/resetPassword").catch(() => window.location.reload()),
)

export const AsyncResetPasswordScene = () => (
    <ResetPasswordScene
        areaType={SERVICE_BENEFICIARY_AREA}
        errorMessage={getChangePasswordErrorMessage}
        getResetPasswordDisplaySuccessMessage={getResetPasswordDisplaySuccessMessage}
        getResetPasswordScore={getResetPasswordScore}
        isFetching={isFetchingChangePassword}
        loginRoute={PagesArchitecture[Page.LOGIN].route}
        resetPasswordPage={Page.RESET_PASSWORD}
    />
)
