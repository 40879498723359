import {omit} from "lodash"
import {combineReducers} from "redux"
import page, * as fromPage from "../../shared-components/store/temporaries/page/pageReducer"
import * as fromResetPassword from "../../shared-components/store/temporaries/resetPassword/resetPasswordReducer"
import search, * as fromSearch from "../../shared-components/store/temporaries/search/searchReducer"
import stepBar, * as fromStepBar from "../../shared-components/store/temporaries/stepBar/stepBarReducer"
import tabBar, * as fromTabBar from "../../shared-components/store/temporaries/tabBar/tabBarReducer"
import table, * as fromTable from "../../shared-components/store/temporaries/table/tableReducer"
import registration, * as fromRegistration from "../../store/temporaries/registration/registrationReducer"
import parameter, * as fromParameter from "./parameter/parameterReducer"
import {CLEAR_TEMPORARY_VALUE, SAVE_TEMPORARY_VALUE} from "./temporariesActionTypes"

/**
 * Permet de sauvegarder des valeurs temporaires de façon générique
 */
const genericTemporaries = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_TEMPORARY_VALUE:
            return {
                ...state,
                [action.key]: action.value,
            }
        case CLEAR_TEMPORARY_VALUE:
            return omit(state, action.key)
        default:
            return state
    }
}

export const getTemporaryValue = (state, key) => state.genericTemporaries[key]

/**
 * Part of the store to stock temporary data.
 */
const temporaries = combineReducers({
    genericTemporaries,
    page,
    resetPassword: fromResetPassword.resetPassword(),
    search,
    stepBar,
    tabBar,
    table,
    registration,
    parameter,
})

export default temporaries

// PAGE OPERATIONS
/**
 * Return the actual page.
 */
export const getActualPage = state => fromPage.getActualPage(state.page)
/**
 * Return the previous page.
 */
export const getPreviousPage = state => fromPage.getPreviousPage(state.page)

// TABLE OPERATIONS
/**
 * Return the table data (ids).
 * @param tableName the name of the table
 */
export const getTableDataIds = (state, tableName) => fromTable.getIds(state.table, tableName)
/**
 * Return the ids of the selected lines in a table.
 * @param tableName the name of the table
 */
export const getTableSelectedLinesIds = (state, tableName) => fromTable.getSelectedLines(state.table, tableName)
/**
 * Return the ids of the activated lines in a table.
 * @param tableName the name of the table
 */
export const getTableActivatedLinesIds = (state, tableName) => fromTable.getActivatedLines(state.table, tableName)

/**
 * Return if line ID is activated
 * @param tableName the name of the table
 * @param id the line id to test
 */
export const getTableIsLineIdActivated = (state, tableName, id) => fromTable.isLineActivated(state.table, tableName, id)

/**
 * Return the id of the line in edition in this table or null if there
 * is no line in edition.
 * @param tableName the name of the table
 */
export const getTableLineInEditionId = (state, tableName) => fromTable.getLineInEdition(state.table, tableName)

// RESET PASSWORD OPERATIONS
/**
 * Tell if we should display a success message after resetting password
 */
export const getResetPasswordDisplaySuccessMessage = state =>
    fromResetPassword.getResetPasswordDisplaySuccessMessage(state.resetPassword)

export const getPasswordScore = state => fromResetPassword.getPasswordScore(state.resetPassword)

// STEP BAR OPERATIONS
/**
 * Return the actual step selected in the step bar.
 */
export const getStepBarActualStep = state => fromStepBar.getActualStep(state.stepBar)
/**
 * Return if the steps are clickable
 */
export const isStepsClickable = state => fromStepBar.isStepsClickable(state.stepBar)

// SEARCH OPERATIONS
/**
 * Return the results of the town search found in the state.
 */
export const getSearchTownResults = (state, name) => fromSearch.getTownResults(state.search, name)
/**
 * Return the query of the town search found in the state.
 */
export const getSearchTownQuery = (state, name) => fromSearch.getTownQuery(state.search, name)
/**
 * Retourne le "isFetching" de la requête trouvé dans le state.
 */
export const isSearchTownFecthing = (state, name) => fromSearch.isTownFetching(state.search, name)

// TAB BAR OPERATIONS
/**
 * Return the actual tab selected in the tab bar.
 */
export const getTabBarActualTab = state => fromTabBar.getActualTab(state.tabBar)

// REGISTRATION OPERATIONS
/**
 * Retourne les valeurs actuelles de l'inscription
 */
export const getRegistration = state => fromRegistration.getRegistration(state.registration)

// Parameter operations

/**
 * Retourne les valeurs de parametre
 */
export const getParameter = (state, key) => fromParameter.getParameter(state.parameter)[key]
