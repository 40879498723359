import {RESET_PARAMETER, SAVE_PARAMETER} from "./parameterActionType"

const parameter = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_PARAMETER:
            return {
                ...state,
                ...action.parameter,
            }
        case RESET_PARAMETER:
            return {}
        default:
            return state
    }
}

export default parameter

/**
 * Retourne les informations d'instruction
 */
export const getParameter = state => state
