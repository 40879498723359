// Utilisé dans le cadre de la personnalisation de l'espace
export const LOCAL_STORAGE_PERSO_NUMERO_CLIENT = "LOCAL_STORAGE_PERSO_NUMERO_CLIENT"
export const LOCAL_STORAGE_PERSO_CLIENT = "LOCAL_STORAGE_PERSO_CLIENT"

// Usages généraux

// Clés de commandes prestations
export const LOCAL_STORAGE_COMMANDE_CHOIX_DISPOSITIF = "LOCAL_STORAGE_COMMANDE_CHOIX_DISPOSITIF"
export const LOCAL_STORAGE_COMMANDE_INFO_COMPL = "LOCAL_STORAGE_COMMANDE_INFO_COMPL"
export const LOCAL_STORAGE_COMMANDE_ATTRIBUTION = "LOCAL_STORAGE_COMMANDE_ATTRIBUTION"
export const LOCAL_STORAGE_COMMANDE_PRESTATION = "LOCAL_STORAGE_COMMANDE_PRESTATION"
export const LOCAL_STORAGE_COMMANDE_INFO_PAIEMENT = "LOCAL_STORAGE_COMMANDE_INFO_PAIEMENT"
export const LOCAL_STORAGE_COMMANDE_CONFIRMATION = "LOCAL_STORAGE_COMMANDE_CONFIRMATION"

export const LOCAL_STORAGE_COMMANDE_KEYS = [
    LOCAL_STORAGE_COMMANDE_CHOIX_DISPOSITIF,
    LOCAL_STORAGE_COMMANDE_INFO_COMPL,
    LOCAL_STORAGE_COMMANDE_ATTRIBUTION,
    LOCAL_STORAGE_COMMANDE_PRESTATION,
    LOCAL_STORAGE_COMMANDE_INFO_PAIEMENT,
    LOCAL_STORAGE_COMMANDE_CONFIRMATION,
]
