import dayjs from "dayjs"
import * as fromLocalStorage from "../../localStorage/localStorageReducer"

/**
 * Get the texts from the store.
 */
export const getCookiesChoices = state => fromLocalStorage.getCookiesChoices(state.localStorage)

/**
 * Return if cookies modal is opened and set it opened by default if undefined
 */
export const isCookiesModalOpened = state =>
    getCookiesChoices(state).isModalOpened === undefined ? true : getCookiesChoices(state).isModalOpened

/**
 * Return actual modal step, HOME by default
 */
export const getModalStep = state => (getCookiesChoices(state).step ? getCookiesChoices(state).step : "HOME")

/**
 * Return if user accepted google analytics cookies
 */
export const isUserAcceptedCookies = state => getCookiesChoices(state).enabled

/**
 * Return if user acceptance is expired
 */
export const isCookiesUserAcceptanceExpired = state =>
    dayjs(getCookiesChoices(state).expirationDate).isSameOrBefore(dayjs())
