import {isEmpty} from "lodash"
import * as fromRequests from "../../requests/requestsReducer"

export const getPayload = (state, requestKeyName) => fromRequests.getPayload(requestKeyName, state.requests)
export const getPayloadAsArray = (state, requestKeyName) =>
    isEmpty(fromRequests.getPayload(requestKeyName, state.requests))
        ? []
        : fromRequests.getPayload(requestKeyName, state.requests)
export const getPayloadAsOptions = (state, requestKeyName) =>
    isEmpty(fromRequests.getPayload(requestKeyName, state.requests))
        ? []
        : fromRequests
              .getPayload(requestKeyName, state.requests)
              .map((el, i) => ({value: el.id, text: el.label, key: i}))
export const getPayloadEntities = (state, requestKeyName) =>
    fromRequests.getPayloadEntities(requestKeyName, state.requests)
export const getPayloadEntity = (requestKeyName, state, id) =>
    fromRequests.getPayloadEntity(requestKeyName, state.requests, id)
export const getPayloadIds = (state, requestKeyName) => fromRequests.getPayloadIds(requestKeyName, state.requests)
export const isAlreadyRequested = (state, requestKeyName) =>
    fromRequests.isAlreadyRequested(requestKeyName, state.requests)
export const isFetching = (state, requestKeyName) => fromRequests.isFetching(requestKeyName, state.requests)
export const getErrorMessage = (state, requestKeyName) => fromRequests.getErrorMessage(requestKeyName, state.requests)
