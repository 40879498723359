import {capitalize} from "lodash"
import * as fromSessionStorage from "../../sessionStorage/sessionStorageReducer"

/**
 * Get the user token from the state.
 */
export const getUserToken = state => fromSessionStorage.getUserToken(state.sessionStorage)

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromSessionStorage.getExpirationDate(state.sessionStorage)

/**
 * Verify is user is connected.
 */
export const isUserConnected = state => !!getUserToken(state)

/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromSessionStorage.getUserPermissions(state.sessionStorage)

/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromSessionStorage.getUserDetails(state.sessionStorage)

/**
 * Retourne l'id du bénéficiaire correspondant à l'espace sélectionné
 */

export const getSelectedAreaBeneficiaireId = state => getSelectedArea(state)?.idBeneficiaire

export const getSelectedAreaClientId = state => getSelectedArea(state)?.idClient

export const getUserAreaChoices = state => getUserDetails(state)?.espaces

export const getSelectedArea = state => getUserDetails(state)?.selectedArea

export const getUserFirstName = state => {
    const userDetails = getUserDetails(state)
    return userDetails && capitalize(userDetails.prenom)
}

/**
 * Get user full name
 */
export const getUserFullname = state => {
    const userDetails = getUserDetails(state)
    return userDetails && capitalize(userDetails.prenom) + " " + userDetails.nom
}

export const getSelectedAreaUserCivilityAndFullName = state => {
    const selectedArea = getSelectedArea(state)

    return (
        selectedArea &&
        capitalize(selectedArea.civiliteBeneficiaire) +
            " " +
            capitalize(selectedArea.prenomBeneficiaire) +
            " " +
            selectedArea.nomBeneficiaire
    )
}

export const getSelectedAreaUserFullName = state => {
    const selectedArea = getSelectedArea(state)

    return selectedArea && selectedArea.prenomBeneficiaire + " " + selectedArea.nomBeneficiaire
}
/**
 * Retourne l'id du bénénéficiaire connecté
 */
export const getMainBeneficiaireId = state => {
    return getUserDetails(state)?.beneficiaireId
}

/**
 * Retourne vrai ou faux c'est un bénéficiaire, sinon c'est un simple contact (sans BENEFICIAIRE en BDD)
 */
export const isUserABeneficiaire = state => {
    return !!getUserDetails(state)?.beneficiaireId
}

/**
 * Retourne vrai ou faux si c'est un bénéficiaire qui a sélectionné son propre espace
 */
export const isUserConnectedAsMainBeneficiaire = state => {
    return getUserDetails(state)?.beneficiaireId === getSelectedArea(state)?.idBeneficiaire
}
