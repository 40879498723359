/**
 * Intégration d'un champ d'autocomplétion d'adresse via l'API https://developers.google.com/maps/documentation/javascript/place-autocomplete
 * Une propriété nommée REACT_APP_GOOGLE_API_KEY doit être intégrée dans le .env et .env.dev du projet parent (Exemples disponibles sur Mon Espace Personnel & Servidom New)
 */
const initGoogleAPIPlacesScript = () => {
    const google = document.createElement("script")
    google.src =
        "https://maps.googleapis.com/maps/api/js?key=" +
        process.env.REACT_APP_GOOGLE_API_KEY +
        "&libraries=places&callback=Function.prototype"
    document.body.appendChild(google)
}

export default initGoogleAPIPlacesScript
