import {useMatomo} from "@datapunt/matomo-tracker-react"
import {isEmpty} from "lodash"
import PropTypes from "prop-types"
import React, {Suspense, useEffect} from "react"
import {connect, useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router"
import {Route, Switch, useLocation, withRouter} from "react-router-dom"
import * as Events from "react-scroll"
import {Dimmer, Loader} from "semantic-ui-react"
import CookiesNotificationContainer from "./../shared-components/components/CookiesNotification/cookiesNotificationContainer"
import {AsyncRoutingLayout} from "./asyncScenes"
import "./mainLayout.scss"
import {LOCAL_STORAGE_PERSO_NUMERO_CLIENT} from "../constants/localStorageValuesKeys"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {EBSRequestKeys} from "../constants/requests"
import CheckBrowserContainer from "../shared-components/components/CheckBrowser/checkBrowserContainer"
import NotificationsContainer from "../shared-components/components/Notifications/notificationsContainer"
import {useIsResponsive} from "../shared-components/hooks/useIsResponsive"
import {clearLocalStorageValue, saveLocalStorageValue} from "../store/localStorage/localStorageActions"
import {getLocalStorageValue} from "../store/selectors/localStorage/genericLocalStorageSelectors"
import {getNotifications} from "../store/selectors/notifications/notificationsSelectors"
import {getPayload, isFetching} from "../store/selectors/requests/requestsSelectors"
import {getSelectedArea, isUserConnected} from "../store/selectors/sessionStorage/userSelectors"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"
import {
    getPersonnalisationByNumClient,
    getPersonnalisationClientsLogos,
    getPersonnalisationLogoByNumClient,
} from "../actions/user/personnalisationActions"
import {
    getCookiesChoices,
    getModalStep,
    isCookiesModalOpened,
    isCookiesUserAcceptanceExpired,
    isUserAcceptedCookies,
} from "../store/selectors/localStorage/cookiesSelectors"

const MainLayout = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const isResponsive = useIsResponsive()
    const {search} = useLocation()

    const actualPage = useSelector(state => getActualPage(state))
    const selectedArea = useSelector(state => getSelectedArea(state))
    const isConnected = useSelector(state => isUserConnected(state))

    const numClientFromLocalStorage = useSelector(state =>
        getLocalStorageValue(state, LOCAL_STORAGE_PERSO_NUMERO_CLIENT),
    )

    const personnalisationClientsLogos = useSelector(state =>
        getPayload(state, EBSRequestKeys.PERSONNALISATION_CLIENTS_LOGOS),
    )

    const isFetchingPersonnalisation = useSelector(state => isFetching(state, EBSRequestKeys.PERSONNALISATION))
    const isFetchingPersonnalisationLogo = useSelector(state => isFetching(state, EBSRequestKeys.PERSONNALISATION_LOGO))

    const {trackPageView} = useMatomo()

    // On récupère les logos client pour les afficher lors de la selection, et pour conditionner l'affichage de certains elements si nbClients > 1
    useEffect(() => {
        if (isConnected && isEmpty(personnalisationClientsLogos)) {
            dispatch(getPersonnalisationClientsLogos())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isConnected])

    // On vérifie si le choix de l'espace (client) a bien été fait, sinon on redirige vers la page de choix
    useEffect(() => {
        if (isConnected && numClientFromLocalStorage === undefined) {
            history.push(PagesArchitecture[Page.CLIENT_CHOICE].route)
        }
    }, [history, isConnected, numClientFromLocalStorage])

    // On vérifie si le choix de l'espace (beneficiaire) a bien été fait, sinon on redirige vers la page de choix
    useEffect(() => {
        if (isConnected && numClientFromLocalStorage !== undefined && selectedArea === undefined) {
            history.push(PagesArchitecture[Page.AREA_CHOICE].route)
        }
    }, [selectedArea, history?.location?.pathname, history, isConnected, actualPage, numClientFromLocalStorage])

    // A chaque changement de page, on remonte vers le haut
    useEffect(() => {
        // On track la page via Matomo
        trackPageView()

        Events.animateScroll.scrollToTop({containerId: isResponsive ? "navigation-layout" : null})
    }, [actualPage, isResponsive, trackPageView])

    // On vérifie si le numéro de client est présent ou non dans l'URL afin d'appliquer la personnalisation
    useEffect(() => {
        const params = new URLSearchParams(search.substring(1))
        const numClient = params.get("numClient")

        // Si le numClient est égal à 0, on réinitialise, sinon on sauvegarde le numéro
        if (numClient === "0") {
            dispatch(clearLocalStorageValue(LOCAL_STORAGE_PERSO_NUMERO_CLIENT, numClient))
        } else if (numClient !== null) {
            dispatch(saveLocalStorageValue(LOCAL_STORAGE_PERSO_NUMERO_CLIENT, numClient))
        }
    }, [search, dispatch])

    // Si le numéro de client a changé, alors on récupère la personnalisation
    useEffect(() => {
        if (numClientFromLocalStorage !== undefined) {
            dispatch(getPersonnalisationByNumClient(numClientFromLocalStorage))
            dispatch(getPersonnalisationLogoByNumClient(numClientFromLocalStorage)).then(
                () => {},
                () => {},
            )
        }
    }, [numClientFromLocalStorage, dispatch])

    return (
        <>
            <CheckBrowserContainer userAgent={window.navigator.userAgent} />
            <Dimmer.Dimmable>
                <Dimmer active={isFetchingPersonnalisation || isFetchingPersonnalisationLogo} inverted page={true}>
                    <Loader active={isFetchingPersonnalisation || isFetchingPersonnalisationLogo} />
                </Dimmer>
                <div className="main-layout">
                    <Suspense fallback={<div />}>
                        <div className="main-content-notifications">
                            <NotificationsContainer getNotifications={getNotifications} />
                        </div>
                        <Switch>
                            <Route component={AsyncRoutingLayout} path="" />
                        </Switch>
                    </Suspense>
                </div>
                <CookiesNotificationContainer
                    getCookiesChoices={getCookiesChoices}
                    getModalStep={getModalStep}
                    isCookiesModalOpened={isCookiesModalOpened}
                    isCookiesUserAcceptanceExpired={isCookiesUserAcceptanceExpired}
                    isUserAcceptedCookies={isUserAcceptedCookies}
                    newButtons={true}
                />
            </Dimmer.Dimmable>
        </>
    )
}

MainLayout.propsTypes = {
    actualPage: PropTypes.string.isRequired,
    isMenuResponsive: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    actualPage: getActualPage(state),
    isUserConnected: isUserConnected(state),
})

export default withRouter(connect(mapStateToProps)(MainLayout))
