const configureEBSAxiosInterceptors = (interceptors, history, pagesArchitectures, pages, proche) => {
    interceptors.response.use(
        response => {
            return response
        },
        error => {
            if (error?.response && error?.response?.status === 403) {
                // Erreur 403, suspection que le proche n'ai plus accès à cet espace (désactivation de son espace dans le profil de son bénéficiaire)
                // Alors on redirige vers la page de choix de dispositif
                history.push(pagesArchitectures[pages.AREA_CHOICE].route)
            }
            return Promise.reject(error)
        },
    )
}

export default configureEBSAxiosInterceptors
