import {combineReducers} from "redux"
import authority, * as fromAuthority from "../../../shared-components/store/sessionStorage/user/authority/authorityReducer"
import details, * as fromDetails from "./details/detailsReducer"

/**
 * User reducer.
 */
const user = combineReducers({
    authority: authority(),
    details,
})

export default user

// OPERATIONS ON AUTHORITY
/**
 * Get the token from the state.
 */
export const getUserToken = state => fromAuthority.getToken(state.authority)
/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromAuthority.getExpirationDate(state.authority)
/**
 * Get the user permissions from the state.
 */
export const getUserPermissions = state => fromAuthority.getPermissions(state.authority)

// OPERATIONS ON DETAILS
/**
 * Get user details from the store.
 */
export const getUserDetails = state => fromDetails.getDetails(state.details)
