/**
 * Default values for global style.
 * The structure is a JSON containing key/value pairs.
 */
const defaultStyle = {
    dangerColor: "#f04e41",
    primaryColor: "#6bb6e6",
    secondaryColor: "#1d3561",
    successColor: "#179777",
    tertiaryColor: "#a1b1bc",
    warningColor: "#ffd600",
}

export default defaultStyle
