import "babel-polyfill"
import "dayjs/locale/fr"
import React from "react"
import {render} from "react-dom"
import "url-search-params-polyfill"
import Root from "./Root"
import {Page} from "./constants/page/pages"
import {PagesArchitecture} from "./constants/page/pagesArchitecture"
import {VersionKeys, Versions} from "./constants/version/version"
import history from "./history"
import "./index.css"
import configureEBSAxiosInterceptors from "./services/configureEBSAxiosInterceptors"
import dayjsConfig from "./shared-components/dayjs"
import {checkVersion} from "./shared-components/services/checkVersion"
import configureAxios from "./shared-components/services/configureAxios"
import configureStore from "./shared-components/services/configureStore"
import initGoogleAPIPlacesScript from "./shared-components/services/initGoogleAPIPlacesScript"
import "./shared-components/styles/icons/css/all.css"
import reducers from "./store/index"
import {getExpirationDate, getUserPermissions, getUserToken} from "./store/selectors/sessionStorage/userSelectors"
import "./styles/_text-styles.scss"

// Paramétrage de dayjs
dayjsConfig()

// Check the version and reset local storage if necessary.
checkVersion(VersionKeys, Versions)

// Launch store configuration and loading from local storage.
const store = configureStore(reducers)

// Configure axios for API calls.
const interceptors = configureAxios(
    store,
    history,
    getExpirationDate,
    getUserToken,
    PagesArchitecture,
    Page,
    getUserPermissions,
)

// Configuration d'axios spécifique à cet espace
configureEBSAxiosInterceptors(interceptors, history, PagesArchitecture, Page, true)

// Google API Places Script
initGoogleAPIPlacesScript()

// Normal root.
render(<Root store={store} />, document.getElementById("root"))
